<template>

    <BaseForm ref="form" @submitted="submit()">

        <LoadingOverlay :loading="loading">
                
            <TabGroup class="mb-6">

                <TabList class="-mt-6 -mx-6 mb-6"/>

                <TabPanel :title="$t('labels.general')">

                    <div class="grid gap-6">

                        <div class="grid gap-6 md:grid-cols-3">

                            <BaseInputGroup :label="$t('labels.name-first')" :error="form?.error('first_name')">
                                <BaseInput v-model="payload.first_name"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.name-last')" :error="form?.error('last_name')">
                                <BaseInput v-model="payload.last_name"/>
                            </BaseInputGroup>

                            <BaseInputGroup :label="$t('labels.birthdate')" :error="form?.error('birthdate')">
                                <BaseInput v-model="payload.birthdate" v-maska="'####-##-##'"/>
                            </BaseInputGroup>

                        </div>

                        <BaseInputGroup v-if="isAdmin" :label="$t('labels.parent')" :error="form?.error('user_id')">
                            <ParentListbox v-model="payload.user_id" :creatable="true" :create="() => $refs.parents?.create()"/>
                        </BaseInputGroup>

                        <BaseInputGroup v-if="isAdmin">
                            <BaseToggle v-model="payload.update_required">{{ $t('labels.update-required') }}</BaseToggle>
                        </BaseInputGroup>

                    </div>

                </TabPanel>

                <TabPanel :title="$t('labels.school')">

                    <div class="grid gap-6">

                        <BaseInputGroup :label="$t('labels.school')" :error="form?.error('client_id')">
                            <ClientListbox
                                v-model="payload.client_id"
                                :creatable="isAdmin"
                                :create="() => $refs.clients?.create()"/>
                        </BaseInputGroup>

                        <BaseInputGroup :label="$t('labels.grade')" :error="form?.error('grade_id')">
                            <GradeListbox
                                v-model="payload.grade_id"
                                :creatable="isAdmin"
                                :create="() => $refs.grades?.create()"/>
                        </BaseInputGroup>

                        <BaseInputGroup :label="$t('labels.bus-number')" :error="form?.error('bus_number')">
                            <BaseInput v-model="payload.bus_number"/>
                        </BaseInputGroup>

                        <BaseInputGroup :label="$t('labels.group')" :error="form?.error('group')">
                            <BaseInput v-model="payload.group"/>
                        </BaseInputGroup>

                        <BaseInputGroup :label="$t('labels.return-type')" :error="form?.error('return_type')">
                            <BaseListbox v-model="payload.return_type" :searchable="false" :options="returnTypes">
                                <template #label="{ option }">
                                    <div class="flex flex-col">
                                        <span class="font-medium" v-text="option.internal.text"></span>
                                        <span v-if="option.internal.desc" class="font-medium text-muted fs-8" v-text="option.internal.desc"></span>
                                    </div>
                                </template>
                            </BaseListbox>
                        </BaseInputGroup>

                        <BaseInputGroup v-if="enableEducator" :label="$t('labels.educator')" :error="form?.error('educator')">
                            <BaseInput v-model="payload.educator"/>
                        </BaseInputGroup>

                    </div>

                </TabPanel>

                <TabPanel :title="$t('labels.conditions')">

                    <div class="grid gap-6">

                        <BaseInputGroup :label="$t('labels.conditions')" :error="form?.error('conditions')">
                            <ConditionListbox
                                v-model="payload.conditions"
                                :multiple="true"
                                :creatable="isAdmin"
                                :create="() => $refs.conditions?.create()"/>
                        </BaseInputGroup>

                        <BaseInputGroup v-if="enableNotes" :label="$t('labels.notes-conditions')" :error="form?.error('notes_medical')">
                            <Tiptap v-model="payload.notes_medical"/>
                        </BaseInputGroup>

                    </div>

                </TabPanel>

                <TabPanel v-if="isAdmin" :title="$t('labels.notes')">

                    <div class="grid gap-6">

                        <BaseInputGroup :label="$t('labels.notes-private')" :error="form?.error('notes')">
                            <Tiptap v-model="payload.notes_internal"/>
                        </BaseInputGroup>

                    </div>

                </TabPanel>

            </TabGroup>

            <BaseButton :disabled="submitting" :loading="submitting">
                {{ $t(action) }}
            </BaseButton>

        </LoadingOverlay>

        <BaseModal v-if="isAdmin" :title="$t('labels.client-create')">
            <template #body>
                <div class="p-6">
                    <ClientForm ref="clients"/>
                </div>
            </template>
        </BaseModal>

        <BaseModal v-if="isAdmin" :title="$t('labels.parent-create')">
            <template #body>
                <div class="p-6">
                    <ParentForm ref="parents"/>
                </div>
            </template>
        </BaseModal>

        <BaseDrawer v-if="isAdmin" :title="$t('labels.condition-create')">
            <ConditionForm ref="conditions"/>
        </BaseDrawer>

        <BaseDrawer v-if="isAdmin" :title="$t('labels.grade-create')">
            <GradeForm ref="grades"/>
        </BaseDrawer>

    </BaseForm>

</template>

<script setup>
    import { useI18n, useReturnTypes } from '@/composables/i18n'
    import { useModelForm } from '@/composables/model-ui'
    import { useChildStore, useConditionStore } from '@/store/model'
    import { useAuth } from '@/composables/auth'
    import { date } from '@/composables/dates'

    const { t } = useI18n()
    const store = useChildStore()
    const conditionStore = useConditionStore()
    const { isAdmin } = useAuth()
    const returnTypes = computed(() => useReturnTypes())

    const messages = {
        created: t('message.child-created'),
        updated: t('message.child-updated'),
        destroyed: t('message.child-destroyed')
    }

    const enableNotes = ref(false)
    const enableEducator = ref(false)    

    const { form, id, payload, loading, submitting, create, edit, submit, mode } = useModelForm({
        store,
        messages,
        editing: (data) => {
            if (payload.value.birthdate) {
                payload.value.birthdate = date(payload.value.birthdate)
            }

            if (payload.value.conditions) {                
                payload.value.conditions = payload.value.conditions.map(item => item.id)
            }
        }
    })

    watch(() => payload.value?.conditions, async (ids) => {
        let result = false

        if (Array.isArray(ids)) {
            const models = await conditionStore?.find(ids)
            
            result = models?.filter(i => i.enable_notes).length > 0
        }

        enableNotes.value = result
    }, { deep: true })
    
    watch(() => payload.value?.return_type, (returnType) => {
        enableEducator.value = 'daycare' === returnType
    }, { deep: true })

    const action = computed(() => id.value  ? 'labels.submit-child-edit' : 'labels.submit-child-create')

    defineExpose({ mode, create, edit })
</script>