<template>
    
    <div class="flex items-center relative w-full">
        
        <slot name="prepend"/>
        
        <div class="input-group">
            <slot name="groupPrepend"/>
            <input class="form-control form-control-solid" :class="classes" :disabled="disabled" :type="type" v-model="model" v-bind="$attrs"/>
            <slot name="groupAppend"/>
        </div>
        
        <slot name="append"/>
  
    </div>

</template>

<script setup>
    const emit = defineEmits(['update:modelValue'])

    const props = defineProps({
        disabled: { type: Boolean, default: false },
        modelValue: { type: [Number, String] },
        type: { type: String, default: 'text' }
    })

    const model = useVModel(props, 'modelValue', emit)
    const disabled = toRef(props, 'disabled')
    const type = toRef(props, 'type')

    const classes = computed(() => ({
        'bg-transparent': !disabled.value,
        'bg-gray-100': disabled.value
    }))
</script>

<script>export default { inheritAttrs: false }</script>
