<template>

    <BaseContainer>

        <header class="flex flex-col md:flex-row md:items-center md:justify-between gap-4 md:gap-6 mb-6">
        
            <div class="block">
                <h1 class="text-gray-800 font-bold text-xl leading-none mb-2" v-text="$t('labels.clients')"></h1>
                <Breadcrumbs :current="Route.CLIENT_VIEW"/>
            </div>

            <div class="flex flex-col md:flex-row md:justify-end gap-4">
                <BaseButton @click.prevent="edit()">{{ $t('labels.client-edit') }}</BaseButton>
                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>
            </div>
        
        </header>
        
        <div class="flex flex-col gap-6">
                
            <LoadingOverlay :loading="loading">
                    
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.general') }}</span>
                    </template>

                    <dl class="flex flex-col">

                        <div class="pb-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.type') }}</dt>
                            <dd class="block">{{ model?.type ? $t(`enum.client-type.${model.type}`) : '-' }}</dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.name') }}</dt>
                            <dd class="block">{{ model?.name || '-' }}</dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.sector') }}</dt>
                            <dd class="block">{{ model?.sector?.name || '-' }}</dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.email') }}</dt>
                            <dd class="block">{{ model?.email || '-' }}</dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.phone') }}</dt>
                            <dd class="block">{{ model?.phone || '-' }}</dd>
                        </div>

                    </dl>

                </BaseCard>

            </LoadingOverlay>

            <LoadingOverlay :loading="loading">
                    
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.address') }}</span>
                    </template>

                    <dl class="flex flex-col">

                        <div class="pb-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.address-line1') }}</dt>
                            <dd class="block">{{ model?.address1 || '-' }}</dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.address-line2') }}</dt>
                            <dd class="block">{{ model?.address2 || '-' }}</dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.address-city') }}</dt>
                            <dd class="block">{{ model?.city || '-' }}</dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.address-region') }}</dt>
                            <dd class="block">{{ model?.province || '-' }}</dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.address-postal') }}</dt>
                            <dd class="block">{{ model?.postal_code || '-' }}</dd>
                        </div>

                        <div class="py-3 border-t border-gray-200 first:border-none">
                            <dt class="block font-semibold mb-2">{{ $t('labels.address-country') }}</dt>
                            <dd class="block">{{ model?.country || '-' }}</dd>
                        </div>

                    </dl>

                </BaseCard>

            </LoadingOverlay>

            <LoadingOverlay v-if="model?.contacts?.length > 0" :loading="loading">
            
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.contacts') }}</span>
                    </template>

                    <dl class="flex flex-col">

                        <div v-for="contact in model?.contacts" :key="contact.id" class="py-3 border-t border-gray-200 first:border-none">

                            <dt class="block font-semibold mb-2">{{ contact.name }}</dt>

                            <dd class="block">

                                <ul class="pl-0">
                                    <li><strong>{{ $t('labels.email') }}:</strong> {{ contact.email || '-' }}</li>
                                    <li><strong>{{ $t('labels.phone') }}:</strong> {{ contact.phone || '-' }}</li>
                                    <li><strong>{{ $t('labels.role') }}:</strong> {{ contact.role ? $t(`enum.contact-role.${contact.role}`) : '-' }}</li>
                                    <li><strong>{{ $t('labels.primary') }}:</strong> {{ contact.primary ? $t('enum.boolean.true') : $t('enum.boolean.false') }}</li>
                                </ul>

                            </dd>

                        </div>

                    </dl>

                </BaseCard>

            </LoadingOverlay>

            <LoadingOverlay :loading="loading">
            
                <BaseCard>

                    <template #header>
                        <span class="card-title fs-6 fw-bold">{{ $t('labels.files') }}</span>
                    </template>

                    <ul class="pl-0 report-types">

                        <li>
                            <a class="inline-flex items-center hover:text-primary" href="#" @click.prevent="$refs.report?.generate('activities', { client: model?.id })">
                                <i class="fa-duotone fa-file-pdf mr-2"></i>{{ $t('enum.report.activities') }}
                            </a>
                        </li>

                        <li>
                            <a class="inline-flex items-center hover:text-primary" href="#" @click.prevent="$refs.report?.generate('employees', { client: model?.id })">
                                <i class="fa-duotone fa-file-pdf mr-2"></i>{{ $t('enum.report.employees') }}
                            </a>
                        </li>     
                        
                        <li>
                            <a class="inline-flex items-center hover:text-primary" href="#" @click.prevent="$refs.report?.generate('grades', { client: model?.id, session: reportSession })">
                                <i class="fa-duotone fa-file-pdf mr-2"></i>{{ $t('enum.report.grades') }}
                            </a>
                            <SessionListbox v-model="reportSession" :first="true" :creatable="false" :clearable="false" />
                        </li>

                    </ul>

                </BaseCard>

            </LoadingOverlay>

            <div class="flex md:justify-end gap-2 mb-6">

                <BaseButton @click.prevent="edit()">{{ $t('labels.client-edit') }}</BaseButton>

                <BaseButton variant="secondary" @click.prevent="index()">{{ $t('labels.back') }}</BaseButton>

            </div>

        </div>

    </BaseContainer>

    <ReportModal ref="report"/>

</template>

<script setup>
    import { ref, watch } from 'vue'
    import Route from '@/constants/route'
    import { date, time } from '@/composables/dates'
    import { useTrans } from '@/composables/i18n'
    import { useModelView } from '@/composables/model-ui'
    import { useClientStore } from '@/store/model'
    import SessionListbox from '@/components/SessionListbox.vue'

    const store = useClientStore()
    const trans = useTrans()
    const reportSession = ref(null)

    const { loading, model, index, edit } = useModelView({ 
        store: store, 
        routes: {
            edit: Route.CLIENT_EDIT,
            index: Route.CLIENT_INDEX
        }
    })

    watch(reportSession, (newValue) => {
        console.log('reportSession.value changed:', newValue)
    })
</script>
