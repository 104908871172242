import axios from '@/plugins/axios'

export const attachments = {
    index: async (params) => axios.get('/api/attachments', { params }),
    show: async (id) => axios.get(`/api/attachments/${id}`),
    destroy: async (id) => axios.delete(`/api/attachments/${id}`),
    update: async (id, payload) => axios.post(`/api/attachments/${id}`, payload),
    rename: async (id, payload) => axios.put(`/api/attachments/rename/${id}`, payload),
    store: async (images) => {
        const formData = new FormData()

        images.forEach(image => { formData.append('images[]', image) })

        return axios.post('/api/attachments', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}

export const auth = {
    login: async (email, password, remember) => axios.post('/api/auth/login', { email, password, remember }),
    logout: async () => axios.post('/api/auth/logout'),
    register: async (payload) => axios.post('/api/auth/register', payload),
    check: async () => axios.get('/api/auth/check'),
    impersonate: async (id) => axios.post(`/api/auth/impersonate/${id}`)
}

export const password = {
    reset: async (email) => axios.post('/api/password/reset', { email }),
    update: async (payload) => axios.post('/api/password/update', payload)
}

export const activities = {
    index: async (params) => axios.get('/api/activities', { params }),
    show: async (id) => axios.get(`/api/activities/${id}`),
    store: async (payload) => axios.post('/api/activities', payload),
    update: async (id, payload) => axios.post(`/api/activities/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/activities/${id}`),
    clone: async (id) => axios.post(`/api/activities/${id}/clone`)
}

export const activityGroups = {
    // index: async (params) => axios.get('/api/activity-groups', { params }),
    // show: async (id) => axios.get(`/api/activity-groups/${id}`),
    // store: async (payload) => axios.post('/api/activity-groups', payload),
    // update: async (id, payload) => axios.post(`/api/activity-groups/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/activity-groups/${id}`),
}

export const cart = {
    show: async () => axios.get('/api/cart'),
    add: async (payload) => axios.post('/api/cart/add', payload),
    update: async (payload) => axios.post('/api/cart/update', payload),
    remove: async (key) => axios.delete('/api/cart', { params: { key } }),
    removeOption: async (key, option) => axios.delete('/api/cart', { params: { key, option } }),
    checkout: async () => axios.post('/api/cart/checkout'),
    payments: async (number) => axios.post('/api/cart/payments', { number }),
    override: async (number) => axios.post('/api/cart/override', { number })
}

export const categories = {
    index: async (params) => axios.get('/api/categories', { params }),
    show: async (id) => axios.get(`/api/categories/${id}`),
    store: async (payload) => axios.post('/api/categories', payload),
    update: async (id, payload) => axios.post(`/api/categories/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/categories/${id}`)
}

export const children = {
    index: async (params) => axios.get('/api/children', { params }),
    show: async (id) => axios.get(`/api/children/${id}`),
    store: async (payload) => axios.post('/api/children', payload),
    update: async (id, payload) => axios.post(`/api/children/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/children/${id}`)
}

export const clients = {
    index: async (params) => axios.get('/api/clients', { params }),
    show: async (id) => axios.get(`/api/clients/${id}`),
    store: async (payload) => axios.post('/api/clients', payload),
    update: async (id, payload) => axios.post(`/api/clients/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/clients/${id}`)
}

export const conditions = {
    index: async (params) => axios.get('/api/conditions', { params }),
    show: async (id) => axios.get(`/api/conditions/${id}`),
    store: async (payload) => axios.post('/api/conditions', payload),
    update: async (id, payload) => axios.post(`/api/conditions/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/conditions/${id}`)
}

export const courses = {
    index: async (params) => axios.get('/api/courses', { params }),
    show: async (id) => axios.get(`/api/courses/${id}`),
    store: async (payload) => axios.post('/api/courses', payload),
    update: async (id, payload) => axios.post(`/api/courses/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/courses/${id}`)
}

export const grades = {
    index: async (params) => axios.get('/api/grades', { params }),
    show: async (id) => axios.get(`/api/grades/${id}`),
    store: async (payload) => axios.post('/api/grades', payload),
    update: async (id, payload) => axios.post(`/api/grades/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/grades/${id}`)
}

export const messages = {
    index: async (params) => axios.get('/api/messages', { params }),
    show: async (id) => axios.get(`/api/messages/${id}`),
    store: async (payload) => axios.post('/api/messages', payload),
    update: async (id, payload) => axios.post(`/api/messages/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/messages/${id}`)
}

export const moneris = {
    send: async (params) => axios.post('/api/moneris/send', params),
}

export const orders = {
    index: async (params) => axios.get('/api/orders', { params }),
    show: async (id) => axios.get(`/api/orders/${id}`),
    store: async () => axios.post('/api/orders'),
    update: async (id, payload) => axios.post(`/api/orders/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/orders/${id}`)
}

export const options = {
    categories: async (params) => axios.get('/api/options/categories', { params }),
    clients: async (params) => axios.get('/api/options/clients', { params }),
    conditions: async (params) => axios.get('/api/options/conditions', { params }),
    courses: async (params) => axios.get('/api/options/courses', { params }),
    employees: async (params) => axios.get('/api/options/employees', { params }),
    grades: async (params) => axios.get('/api/options/grades', { params }),
    orders: async (params) => axios.get('/api/options/orders', { params }),
    parents: async (params) => axios.get('/api/options/parents', { params }),
    schoolBoards: async (params) => axios.get('/api/options/school-boards', { params }),
    sectors: async (params) => axios.get('/api/options/sectors', { params }),
    sessions: async (params) => axios.get('/api/options/sessions', { params }),
    taxRates: async (params) => axios.get('/api/options/tax-rates', { params }),
    adminMessageEmails: async (params) => axios.get('/api/options/message-emails',{params}),
}

export const products = {
    index: async (params) => axios.get('/api/products', { params })
}

export const profile = {
    locale: async (locale) => axios.post('/api/profile/locale', { locale }),
}

export const registrations = {
    index: async (params) => axios.get('/api/registrations', { params }),
    show: async (id) => axios.get(`/api/registrations/${id}`),
    store: async (payload) => axios.post('/api/registrations', payload),
    update: async (id, payload) => axios.post(`/api/registrations/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/registrations/${id}`)
}

export const reports = {
    show: async (id) => axios.get(`/api/reports/${id}`),
    generate: async (report, args) => axios.post(`/api/reports/${report}`, args)
}

export const schedule = {
    index: async (params) => axios.get('/api/schedule', { params }),
    archive: async (id, payload) => axios.post(`/api/schedule/${id}/archive`, payload)
}

export const sectors = {
    index: async (params) => axios.get('/api/sectors', { params }),
    show: async (id) => axios.get(`/api/sectors/${id}`),
    store: async (payload) => axios.post('/api/sectors', payload),
    update: async (id, payload) => axios.post(`/api/sectors/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/sectors/${id}`)
}

export const sessions = {
    index: async (params) => axios.get('/api/sessions', { params }),
    show: async (id) => axios.get(`/api/sessions/${id}`),
    store: async (payload) => axios.post('/api/sessions', payload),
    update: async (id, payload) => axios.post(`/api/sessions/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/sessions/${id}`)
}

export const taxRates = {
    index: async (params) => axios.get('/api/tax-rates', { params }),
    show: async (id) => axios.get(`/api/tax-rates/${id}`),
    store: async (payload) => axios.post('/api/tax-rates', payload),
    update: async (id, payload) => axios.post(`/api/tax-rates/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/tax-rates/${id}`)
}

export const transactions = {
    index: async (params) => axios.get('/api/transactions', { params }),
    show: async (id) => axios.get(`/api/transactions/${id}`),
    store: async (payload) => axios.post('/api/transactions', payload),
    update: async (id, payload) => axios.post(`/api/transactions/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/transactions/${id}`)
}

export const users = {
    index: async (params) => axios.get('/api/users', { params }),
    show: async (id) => axios.get(`/api/users/${id}`),
    store: async (payload) => axios.post('/api/users', payload),
    update: async (id, payload) => axios.post(`/api/users/${id}`, payload),
    destroy: async (id) => axios.delete(`/api/users/${id}`),
    preferences: async (id, preferences) => axios.post(`/api/users/${id}/preferences`, preferences)
}
